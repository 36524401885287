import * as React from "react";
import {
  BooleanInput,
  Datagrid,
  List,
  NumberField,
  TextField,
  useListContext,
} from "react-admin";
import { styled } from "@mui/material/styles";
import { RecipeFilter } from "../../components/Filter/RecipeFilter";
import { ConstantFilter } from "../../components/Filter/ConstantFilter";
import { LinkField } from "../../components/Field/LinkField";
import MarkReviewedButton from "../../components/Button/MarkReviewedButton";

const PREFIX = "ReportReasonList";

const classes = {
  languagesHeader: `${PREFIX}-languagesHeader`,
};

const StyledList = styled(List)({
  [`& .${classes.languagesHeader}`]: { fontWeight: "bold" },
});

const DynamicGrid = (props) => {
  const { data, filterValues } = useListContext();

  let languages = data?.[0]?.language_totals;

  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="total" />
      {languages &&
        Object.keys(languages).map((key) => (
          <LinkField
            headerClassName={classes.languagesHeader}
            source={`language_totals.${key}`}
            label={key.toUpperCase()}
            key={key}
            resource="report-details"
            type="list"
            filter={(record) => ({
              reason: record.id,
              language: key,
              recipe: filterValues.recipe,
              new_only: filterValues.new_only,
            })}
          />
        ))}
    </Datagrid>
  );
};

const reportFilters = [
  <ConstantFilter parameter={"project"} asArray />,
  <RecipeFilter />,
  <BooleanInput source="new_only" alwaysOn />,
];

const BulkActionButtons = () => (
  <>
    <MarkReviewedButton parameter="reasons" />
  </>
);

const ReportReasonsList = (props) => {
  return (
    <StyledList
      {...props}
      filter={{ stats: true }}
      filters={reportFilters}
      perPage={25}
      sort={{ field: "id", order: "ASC" }}
      pagination={false}
      exporter={false}
      actions={false}
    >
      <DynamicGrid bulkActionButtons={<BulkActionButtons />} />
    </StyledList>
  );
};

export default ReportReasonsList;
