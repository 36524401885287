import { useCreatePath, useRecordContext } from "react-admin";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { stringify } from "query-string";
import get from "lodash/get";

export const LinkField = ({ resource, type, filter, source, ...rest }) => {
  const record = useRecordContext(rest);
  const createPath = useCreatePath();

  if (!record) return null;

  return (
    <Link
      component={RouterLink}
      to={{
        pathname: createPath({ resource: resource, type: type }),
        search: stringify({
          page: 1,
          perPage: 25,
          sort: "id",
          order: "ASC",
          filter: JSON.stringify(filter(record)),
        }),
      }}
    >
      {get(record, source)}
    </Link>
  );
};
