import { useRecordContext } from "react-admin";
import { getFlagEmoji } from "../../utils/common";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const Span = styled("span")({
  fontSize: "20pt",
  lineHeight: "1",
});

export const FlagField = (props) => {
  const record = useRecordContext(props);
  if (!record) return null;
  let country = record[props.source];
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={6}>
        <Span>{getFlagEmoji(country)}</Span>
      </Grid>
      <Grid item xs={6}>
        <span>{country}</span>
      </Grid>
    </Grid>
  );
};
