import * as React from "react";
import { ROLE } from "../defaults";

export const toTitleCase = (str) =>
  str
    .replace("_", " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

export const MenuIcon = (src) => {
  return <img alt="icon" width="24" height="24" src={src} />;
};

export const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const canAccess = (permissions, minRequiredRole) => {
  const userRole = ROLE[permissions?.[0]];

  return userRole !== undefined && userRole <= minRequiredRole;
};
