import { CheckOutlined, CancelOutlined } from "@mui/icons-material";
import { useState } from "react";
import {
  Button,
  Confirm,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
  useCreate,
} from "react-admin";

const MarkReviewedButton = ({ parameter }) => {
  const { selectedIds, filterValues, resource } = useListContext();
  const [isOpen, setIsOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [create, { isLoading }] = useCreate();

  const handleClick = () => setIsOpen(true);
  const handleDialogClose = () => setIsOpen(false);

  const handleConfirm = () => {
    create(
      "reports/reviews",
      { data: { [parameter]: selectedIds, filter: filterValues } },
      {
        onSuccess: () => {
          refresh();
          notify("Reports updated");
          unselectAll();
        },
        onError: (error) =>
          notify("Error: reports not updated", { type: "warning" }),
      }
    );
    setIsOpen(false);
  };

  return (
    <>
      <Button
        label="Mark Reviewed"
        onClick={handleClick}
        startIcon={<CheckOutlined />}
      />
      <Confirm
        isOpen={isOpen}
        loading={isLoading}
        title="Mark reviewed"
        content={`Are you sure you want to mark the selected ${parameter} as reviewed?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        CancelIcon={CancelOutlined}
      />
    </>
  );
};

export default MarkReviewedButton;
