import * as React from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  ExportButton,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { styled } from "@mui/material/styles";

import { RecipeFilter } from "../../components/Filter/RecipeFilter";
import { MpPagination } from "../../components/Pagination/MpPagination";
import { FlagField } from "../../components/Field/FlagField";
import { ConstantFilter } from "../../components/Filter/ConstantFilter";
import MarkReviewedButton from "../../components/Button/MarkReviewedButton";

const PREFIX = "ReportReasonList";

const classes = {
  languagesHeader: `${PREFIX}-languagesHeader`,
};

const StyledList = styled(List)({
  [`& .${classes.languagesHeader}`]: { fontWeight: "bold" },
});

const reportFilters = [
  <TextInput label="Search" source="q" resettable alwaysOn />,
  <ConstantFilter parameter={"language"} />,
  <ReferenceInput
    source={"reason"}
    reference={"report-reasons"}
    perPage={20}
    sort={{
      field: "id",
      order: "ASC",
    }}
    alwaysOn
  >
    <AutocompleteInput
      translateChoice={false}
      sx={{
        minWidth: "30ch",
      }}
    />
  </ReferenceInput>,
  <RecipeFilter />,
  <BooleanInput source="new_only" alwaysOn />,
  <BooleanInput source="has_message" label="With message" alwaysOn />,
];

const BulkActionButtons = () => (
  <>
    <MarkReviewedButton parameter="reports" />
  </>
);

const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const ReportDetailsList = (props) => {
  return (
    <StyledList
      {...props}
      pagination={<MpPagination />}
      filters={reportFilters}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
      actions={<ListActions />}
    >
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <TextField source="id" />
        <TextField source="recipe" sx={{ whiteSpace: "nowrap" }} />
        <TextField source="reason" sx={{ whiteSpace: "nowrap" }} />
        <TextField source="language" />
        <FlagField source="country" />
        <TextField source="message" />
        <BooleanField source="is_reviewed" sx={{ minWidth: "80px" }} />
      </Datagrid>
    </StyledList>
  );
};

export default ReportDetailsList;
