import ReportDetailsList from "./ReportDetailsList";
import Icon from "@mui/icons-material/Comment";

const params = {
  icon: Icon,
  list: ReportDetailsList,
  options: { label: "Details" },
};

export default params;
