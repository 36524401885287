import * as React from "react";

import { Admin, Layout, Resource } from "react-admin";
import authProvider from "./providers/AuthProvider";
import { dataProvider } from "./providers/DataProvider";
import reports from "./routes/Reports";
import parser from "./routes/Parser";
import reportReasons from "./routes/ReportReasons";
import reportDetails from "./routes/ReportDetails";
import { AppBar, lightTheme } from "./components/AppBar/AppBar";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ROLE } from "./defaults";
import { canAccess } from "./utils/common";

const MainLayout = (props) => (
  <>
    <Layout {...props} appBar={AppBar} />
    <ReactQueryDevtools initialIsOpen={false} />
  </>
);

const App = () => (
  <BrowserRouter>
    <Admin
      theme={lightTheme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={MainLayout}
      title="Meal Planner Admin"
      disableTelemetry
      requireAuth
    >
      {(permissions) => (
        <>
          {/*  Parser is for admins only */}
          {canAccess(permissions, ROLE.admin) && (
            <Resource name="parser" {...parser} />
          )}
          <Resource name="reports" {...reports} />
          <Resource name="report-reasons" {...reportReasons} />
          <Resource name="report-details" {...reportDetails} />
        </>
      )}
    </Admin>
  </BrowserRouter>
);

export default App;
