import React from "react";
import { AppBar as RaAppBar } from "react-admin";
import { Typography } from "@mui/material";

export const lightTheme = {
  palette: {
    secondary: {
      main: "#29524A",
    },
  },
};

export const darkTheme = {
  palette: { mode: "dark" },
};

export const AppBar = (props) => (
  <RaAppBar {...props}>
    <Typography flex="1" variant="h6" id="react-admin-title" />
  </RaAppBar>
);
