import ReportReasonsList from "./ReportReasonsList";
import Icon from "@mui/icons-material/FactCheck";

const params = {
  icon: Icon,
  list: ReportReasonsList,
  options: { label: "Reasons" },
};

export default params;
