import * as React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
} from "react-admin";

export const ConstantFilter = ({ parameter, asArray, ...rest }) => {
  let props = {
    source: parameter,
    reference: "constants",
    filter: { parameter: parameter },
    perPage: 100,
    sort: { field: "id", order: "ASC" },
  };

  return asArray ? (
    <ReferenceArrayInput {...props} {...rest}>
      <AutocompleteArrayInput optionValue={"key"} />
    </ReferenceArrayInput>
  ) : (
    <ReferenceInput {...props} {...rest}>
      <AutocompleteInput optionValue={"key"} />
    </ReferenceInput>
  );
};

ConstantFilter.defaultProps = { alwaysOn: true, asArray: false };
