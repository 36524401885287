import * as React from "react";
import {
  Datagrid,
  ExportButton,
  List,
  NumberField,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from "react-admin";
import { styled } from "@mui/material/styles";
import StarsIcon from "@mui/icons-material/Stars";

import { LinkField } from "../../components/Field/LinkField";
import { MpPagination } from "../../components/Pagination/MpPagination";
import MarkReviewedButton from "../../components/Button/MarkReviewedButton";

const ListActions = () => (
  <TopToolbar>
    <ExportButton maxResults={10000} />
  </TopToolbar>
);

const PREFIX = "ReportList";

const classes = {
  languagesHeader: `${PREFIX}-languagesHeader`,
  projectsHeader: `${PREFIX}-projectsHeader`,
};

const StyledList = styled(List)({
  [`& .${classes.languagesHeader}`]: { fontWeight: "bold" },
  [`& .${classes.projectsHeader}`]: { backgroundColor: "#D3D3D3" },
});

const DynamicGrid = (props) => {
  const { data } = useListContext();

  let projects = data?.[0]?.project_rates;
  // let languages = data?.[0]?.language_rates;
  let countries = data?.[0]?.country_rates;

  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="name" />
      <LinkField
        source="total"
        resource="report-reasons"
        type="list"
        filter={(record) => ({ new_only: false, recipe: record.id })}
      />
      <LinkField
        source="new"
        resource="report-reasons"
        type="list"
        filter={(record) => ({ new_only: true, recipe: record.id })}
      />
      <NumberField source="rate" label={<StarsIcon />} />
      {projects &&
        Object.keys(projects).map((key) => (
          <NumberField
            headerClassName={classes.projectsHeader}
            source={`project_rates.${key}`}
            label={key.toUpperCase()}
            key={key}
          />
        ))}
      {countries &&
        Object.keys(countries).map((key) => (
          <NumberField
            headerClassName={classes.languagesHeader}
            source={`country_rates.${key}`}
            label={key.toUpperCase()}
            key={key}
          />
        ))}
    </Datagrid>
  );
};

const reportFilters = [
  <TextInput label="Search" source="q" resettable alwaysOn />,
];

const BulkActionButtons = () => (
  <>
    <MarkReviewedButton parameter="recipes" />
  </>
);

const ReportList = (props) => {
  return (
    <StyledList
      {...props}
      actions={<ListActions />}
      filters={reportFilters}
      perPage={25}
      sort={{ field: "new", order: "DESC" }}
      pagination={<MpPagination />}
    >
      <DynamicGrid bulkActionButtons={<BulkActionButtons />} />
    </StyledList>
  );
};

export default ReportList;
