import * as React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const RecipeOptionRenderer = (choice) => {
  return (
    <Paper
      sx={{
        p: 1,
        margin: "auto",
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="cover" src={choice.cover} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                {choice.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ID: {choice.id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const recipeInputText = (choice) => `[${choice.id}] ${choice.name}`;
const recipeMatchSuggestion = (filter, choice) => {
  return (
    choice.name.toLowerCase().includes(filter.toLowerCase()) ||
    choice.id.toString() === filter
  );
};

export const RecipeFilter = (props) => {
  return (
    <ReferenceInput source="recipe" reference="recipes" perPage={20} {...props}>
      <AutocompleteInput
        optionText={RecipeOptionRenderer}
        inputText={recipeInputText}
        matchSuggestion={recipeMatchSuggestion}
        translateChoice={false}
        sx={{
          minWidth: "45ch",
        }}
        // PaperProps={{
        //   style: {
        //     maxHeight: "80vh",
        //   },
        // }}
        ListboxProps={{
          style: {
            maxHeight: "80vh",
          },
        }}
      />
    </ReferenceInput>
  );
};

RecipeFilter.defaultProps = { alwaysOn: true };
