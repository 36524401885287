import simpleRestProvider from "ra-data-simple-rest";
import config from "../config";
import { fetchUtils } from "react-admin";
import { Auth } from "aws-amplify";

const client = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers();
  }

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  options.headers.set("Authorization", `Bearer ${token}`);
  options.headers.set("x-api-key", config.API_KEY);

  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = simpleRestProvider(
  `${config.API_URL}/admin`,
  client
);
