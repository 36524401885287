import React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import config from "./config";
import { Amplify } from "aws-amplify";

const AWS = require("aws-sdk");

AWS.config.update({
  maxRetries: 1,
  httpOptions: {
    timeout: 300000, // 5 min
    connectTimeout: 5000,
  },
});

// ConsoleLogger.LOG_LEVEL = "DEBUG";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
  Lambda: {
    region: config.PARSE_LAMBDA_REGION,
  },
});

const rootElement = document.getElementById("root");

const root = ReactDOMClient.createRoot(rootElement);
root.render(<App />);
