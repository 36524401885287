import React, { useState } from "react";
import { Title, useNotify } from "react-admin";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Video from "../../assets/dog_loader.mp4";
import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { dataProvider } from "../../providers/DataProvider";

function startPolling(callApiFn, testFn, time) {
  let polling = false; // [1]
  let rejectThis = null;

  const stopPolling = () => {
    if (polling) {
      console.log(new Date(), "Polling was already stopped...");
    } else {
      console.log(new Date(), "Stopping polling...");
      polling = false;
      rejectThis(new Error("Polling cancelled"));
    }
  };

  const promise = new Promise((resolve, reject) => {
    polling = true;
    rejectThis = reject;

    const executePoll = async () => {
      try {
        const result = await callApiFn();
        if (polling && testFn(result)) {
          polling = false;
          resolve(result);
        } else {
          setTimeout(executePoll, time);
        }
      } catch (error) {
        polling = false;
        reject(new Error("Polling cancelled due to API error"));
      }
    };

    setTimeout(executePoll, time);
  });

  return { promise, stopPolling };
}

const checkParsingStatus = (jobId) => {
  return dataProvider.getOne("parse", { id: jobId }).then((response) => {
    console.log("Response2: " + JSON.stringify(response.data));
    return response.data;
  });
};

const invokeLambda = (setIsLoading, setLog, notify) => {
  setIsLoading(true);
  setLog("Log output will be displayed here");
  let startTime = performance.now();
  let logOutput = null;
  let notificationType = "success";
  let notificationMessage = "SUCCESS";

  dataProvider
    .create("parse", {})
    .then((response) => {
      console.log("response: " + JSON.stringify(response));

      const { promise, _stopPolling } = startPolling(
        () => checkParsingStatus(response.data.id),
        (data) => data.status !== "in_progress",
        2000
      );

      promise
        .then((data) => {
          console.log("Here 2", data);
          logOutput = data.log;

          if (data.status === "failed") {
            notificationType = "error";
            notificationMessage = "ERROR";
          }
        })
        .catch((error) => {
          notificationType = "error";
          notificationMessage = `${error}`;
          logOutput = `${error}`;
        })
        .finally(() => {
          let duration = (performance.now() - startTime) / 1000;
          duration = duration.toFixed(2);
          setIsLoading(false);
          setLog(`Finished in ${duration}\n${logOutput}`);

          notify(notificationMessage, {
            type: notificationType,
            autoHideDuration: 15000,
            multiLine: true,
          });
        });
    })
    .catch((error) => {
      console.error(error);
      notificationType = "error";
      notificationMessage = `${error}`;

      setIsLoading(false);
      setLog(`Failed`);

      notify(notificationMessage, {
        type: notificationType,
        autoHideDuration: 15000,
        multiLine: true,
      });
    });
};

const ParserList = (props) => {
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [log, setLog] = useState("Log output will be displayed here");

  return (
    <Card>
      <Title title="Parser" />
      <CardContent>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <LoadingButton
              size="large"
              onClick={() => invokeLambda(setIsLoading, setLog, notify)}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<PlayCircleFilledWhiteOutlinedIcon />}
              variant="contained"
              fullWidth
              sx={{
                background: (theme) => {
                  return theme.palette.mode === "dark"
                    ? "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
                    : "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)";
                },
                boxShadow: (theme) => {
                  return theme.palette.mode === "dark"
                    ? "0 3px 5px 2px rgba(255, 105, 135, .3)"
                    : "0 3px 5px 2px rgba(33, 150, 243, .3)";
                },
                // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                height: 48,
              }}
            >
              {isLoading ? "PARSING IN PROGRESS" : "START PARSING"}
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <CardMedia
              component="video"
              src={Video}
              height={300}
              autoPlay
              loop
              muted
              style={{ display: isLoading ? "block" : "none" }}
            />
          </Grid>
          <Grid item xs={12}>
            <SyntaxHighlighter
              language="python"
              style={monokaiSublime}
              wrapLongLines={true}
            >
              {log}
            </SyntaxHighlighter>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ParserList;
