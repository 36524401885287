import ParserList from "./ParserList";
import { MenuIcon } from "../../utils/common";
import excel from "../../assets/excel.png";

const params = {
  icon: () => MenuIcon(excel),
  list: ParserList,
  options: { label: "Parser" },
};

export default params;
