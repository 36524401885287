import { Auth } from "aws-amplify";
import { toTitleCase } from "../utils/common";
import { ROLE } from "../defaults";
import { toSvg } from "jdenticon";

const getRoles = async () => {
  const session = await Auth.currentSession();
  const payload = session.getAccessToken().decodePayload();
  return payload["cognito:groups"]
    .filter((elem) => elem in ROLE)
    .sort((a, b) => ROLE[a] - ROLE[b]);
};

const authProvider = {
  login: ({ username, password, clientMetadata }) => {
    return Promise.resolve();
  },

  checkError: (error) => {
    // const status = error.status;
    // if (status === 401 || status === 403) {
    //   // localStorage.removeItem("auth");
    //   return Promise.reject();
    // }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  checkAuth: async () => {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      await Auth.federatedSignIn();
      return Promise.resolve();
    }

    try {
      const groups = await getRoles();

      if (groups.length === 0) {
        return Promise.reject({
          message: "You do not have permission to access this resource.",
          // redirectTo: "/no-access",
        });
      }
    } catch (err) {
      return Promise.reject({
        message: err,
      });
    }

    return Promise.resolve();
  },

  logout: async () => {
    await Auth.signOut();
    return Promise.resolve(false);
  },

  getPermissions: getRoles,

  getIdentity: async () => {
    const session = await Auth.currentSession();
    const email = session.getIdToken().decodePayload().email;
    let [firstName, lastName] = email.split("@")[0].split(".");
    const roles = await getRoles();

    let avatar = toSvg(email, 32);

    firstName = toTitleCase(firstName);
    lastName = toTitleCase(lastName);

    return Promise.resolve({
      id: email,
      fullName: `${firstName} ${lastName} [${roles[0] || "anonymous"}]`,
      avatar: `data:image/svg+xml;base64,${btoa(avatar)}`,
    });
  },

  getEmail: async () => {
    const session = await Auth.currentSession();
    const userEmail = session.getIdToken().decodePayload().email;

    return { email: userEmail };
  },
};

export default authProvider;
