import ReportList from "./ReportList";
import Icon from "@mui/icons-material/AssistantPhoto";

const params = {
  icon: Icon,
  list: ReportList,
  options: { label: "Recipe reports" },
};

export default params;
